import { SVGProps, Ref, forwardRef } from 'react'

const SvgLockClosed = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.75 9.75h16.5v13.5H3.75V9.75ZM6.75 9.75V6a5.25 5.25 0 1 1 10.5 0v3.75"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 15.75a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75v0Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgLockClosed)
export default ForwardRef
